import React, { useEffect, useState } from "react";
import ImprintFooter from "../../utils/sharedComponents/ImprintFooter";
import { Link } from "react-router-dom";
import privacy_de from "../../utils/privacy_de.pdf";
import privacy_en from "../../utils/privacy_en.pdf";

const PrivacyStatement = () => {
  const [pdf, setPdf] = useState();
  useEffect(() => {
    const language = localStorage.getItem("language");
    if (language === "de") {
      setPdf(privacy_de);
    } else {
      setPdf(privacy_en);
    }
  }, []);
  return (
    <div>
      {/* <Link to="/">
        <div className="d-flex justify-content-end">
          <button className="btn-smol">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </Link> */}
      <b>Datenschutz</b>

      <div style={{ height: "75vh" }}>
        <iframe
          id="pdf-js-viewer"
          src={"lib/web/viewer.html?file=" + pdf}
          title="webviewer"
          frameBorder="0"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
  );
};

export default PrivacyStatement;
