export default {
  redborder: {
    de: "Sie können jetzt Ihre Fehler einsehen. Da es mehrere Fehler gibt, sollte der Test ggf. die Schulung wiederholt werden",
    en: "You can now view your errors. Since there are several errors, the test should be repeated and the training if necessary",
  },
  alert:{
    de: "Bitte wiederholen Sie den Kurs",
    en: "Please repeat the course",
  }
};
