export const Endpoints = {
  api: "https://chub.compliance.monster/api",
  list_languages: "/languages",
  register: "/register_to_go",
  list_courses: "/education_courses_available_to_go/",
  enrollment: "/education_course_enrollments",
  list_material: "/education_sections_with_materials_by_course_id/",
  quiz: "/education_section_materials_get_test/",
  check_answers: "/education_answer_check_single",
  submit_answers: "/education_test_result_ready_to_go",
  vid: "/education_section_materials_get_video_stream/",
  sub: "/education_section_materials_get_subtitle_stream/",
  pdf: "/education_section_materials_get_file_stream/",
  material_progress: "/education_material_progress",
  certificate: "/education_get_certificate_chub",
  test_stream_video:
    "/education_section_materials_get_video_stream/f817befb-32bc-4e92-975b-9416f14a278e",
  education_section_materials_get_video_pseudo_stream:
    "/education_section_materials_get_video_pseudo_stream/",
};
