import React from "react";
import LogoImage from "../../utils/logo.png";
const Logo = () => {
  return (
    <div id="logoContainer" className="logo d-flex justify-content-center">
      <img src={LogoImage} style={{ width: "50%" }} class="img-fluid"></img>
    </div>
  );
};

export default Logo;
