import React, { useEffect, useState, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Endpoints } from "../../repository/Endpoints";
import { GetMaker } from "../../repository/RequestMaker";
import { Translations } from "../../repository/Translations";
import { Spinner } from "react-bootstrap";
import { Language } from "./Language";
import Logo from "../../utils/sharedComponents/Logo";
import ImprintFooter from "../../utils/sharedComponents/ImprintFooter";
import Modal from "react-modal";
import PrivacyStatement from "../privacyStatement/PrivacyStatement";

Modal.setAppElement("#root");
const Home = () => {
  // inits
  const history = useHistory();
  const [languageAvailable, setLanguageAvailable] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [langToggled, setLangToggled] = useState(false);
  const [cookieChecked, setCookieChecked] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }
  // logics
  const onNext = (e) => {
    e.preventDefault();
    if (langToggled && cookieChecked) {
      history.push({
        pathname: "/userdata",
      });
    }
    if (!langToggled) {
      alert(
        "Bitte wählen Sie die gewünschte Sprache // Please select the requested language"
      );
    }
    if (!cookieChecked) {
      alert(
        'Bitte klicken Sie zum Fortfahren auf das Kontrollkästchen neben der Erklärung "Ich habe die Datenschutzhinweise & Nutzungsbedingungen gelesen ...".'
      );
    }
  };

  const setLanguage = (id) => {
    setLangToggled(true);
    console.log("selected language", id);
    id = id.split(" ");
    localStorage.setItem("language_id", id[0]);
    localStorage.setItem("language", id[1]);
    Translations(id[1], Language);
  };

  useEffect(() => {
    localStorage.clear();
    window.scrollTo(0, 0);
    // localStorage.setItem("tenant_id", "52eef02e-65c1-4c44-bb28-e6f8993fdd2e");//Steam & Power
    localStorage.setItem("tenant_id", "82444c5a-c21b-4db7-9f69-19b39741a8e0");
    localStorage.setItem("language", "de");
    localStorage.setItem("language_id", "1");

    GetMaker(Endpoints.list_languages).then((res) => {
      console.log(res.data);
      setLanguages(res.data);
      setLanguageAvailable(true);
    });
  }, []);

  useLayoutEffect(() => {
    Translations("de", Language);
  }, []);
  // render
  return (
    <>
      <div className="bg-mine">
        <h1 className="display-4" id="title_text">
          Willkommen zu Ihrer
        </h1>
        <h1 className="display-4" id="title_text_2">
          Digitalen Einweisung
        </h1>
        <h1 className="display-4" id="title_text_3">
          Arbeitssicherheit
        </h1>
        <hr className="my-4" />
        <div>
          <div className="input-group mb-3">
            <span className="input-group-text" id="language_text">
              Sprache
            </span>
            {languageAvailable ? (
              <select
                className="form-control"
                id="language_select"
                onChange={(e) => setLanguage(e.currentTarget.value)}
              >
                <option disabled selected>
                  Bitte wählen Sie die gewünschte Sprache // Please select the
                  requested language
                </option>
                {languages
                  .sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1))
                  .map((data, key) => {
                    return (
                      <option key={key} value={data.id + " " + data.lang}>
                        {data.name}
                      </option>
                    );
                  })}
              </select>
            ) : (
              <Spinner animation="grow" />
            )}
          </div>
        </div>

        <div>
          <div className="d-flex flex-row">
            {/* <input
              className="custom-control-input"
              type="checkbox"
              name="cookieMaker"
              id="cookieMaker"
              onChange={(e) => setCookieChecked(e.currentTarget.checked)}
            /> */}
            <div className="d-flex justify-content-start">
              <label className="toggle-control">
                <input
                  type="checkbox"
                  onChange={(e) => setCookieChecked(e.currentTarget.checked)}
                />
                <span className="control"></span>
              </label>
              <span className="margin_left"></span>
              <label
                className="custom-control-label"
                id="privacy_agree_text"
                for="cookieMaker"
                style={{ marginLeft: "30px", marginTop: "-3px" }}
              ></label>
              <span
                id="privacy_agree_link"
                className="btn btn-link"
                style={{
                  padding: 0,
                  marginTop: -5,
                  marginLeft: 2,
                  marginRight: 2,
                }}
                onClick={openModal}
              >
                Privacy
              </span>
              <label
                className="custom-control-label"
                id="privacy_agree_text_2"
                for="cookieMaker"
              ></label>
            </div>
          </div>
        </div>
        <div>
          <div className="margin_top"></div>

          <button
            // className="btn btn-outline-dark btn-lg btn-round"
            className="btn-round"
            id="next_button"
            onClick={onNext}
          >
            <i class="fa fa-forward" aria-hidden="true"></i>
          </button>
          <p id="next_button_statement" style={{ fontSize: 12 }}>
            Bitte klicken Sie zum Fortfahren auf das Kontrollkästchen neben der
            Erklärung "Ich habe die Datenschutzhinweise & Nutzungsbedingungen
            gelesen ...".
          </p>
        </div>

        <a href="/imprint">
          <ImprintFooter>
            <span id="imprint_button">Impressum</span>
          </ImprintFooter>
        </a>

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
            borderRadius: 20,
          }}
        >
          <div className="d-flex justify-content-end">
            <button className="btn-smol" onClick={closeModal}>
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <PrivacyStatement />
        </Modal>
      </div>
    </>
  );
};

export default Home;
