import React, { useState, useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { Accordion, Card, Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { GetMaker, PostMaker } from "../../repository/RequestMaker";
import { Endpoints } from "../../repository/Endpoints";
import { Translations } from "../../repository/Translations";
import Logout from "../../utils/sharedComponents/Logout";
import { Language } from "./Language";
import ImprintFooter from "../../utils/sharedComponents/ImprintFooter";
const Courses = () => {
  // inits
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  // logics
  useEffect(() => {
    window.scrollTo(0, 0);
    GetMaker(
      Endpoints.list_courses +
        localStorage.getItem("tenant_id") +
        "/" +
        localStorage.getItem("language_id")
    ).then((res) => {
      if (res) {
        setCourses(res.data);
        setIsLoading(false);
      }
    });
  }, []);

  useLayoutEffect(() => {
    Translations("", Language);
  }, []);
  const onNext = (course_id, key) => {
    localStorage.setItem("course_id", course_id);
    document.getElementById("confirm" + key).innerHTML = "Loading...";
    PostMaker(
      {
        user_id: localStorage.getItem("user_id"),
        course_id: course_id,
        profile_id: localStorage.getItem("profile_id"),
      },
      Endpoints.enrollment
    ).then((response) => {
      if (response) {
        localStorage.setItem("enrollment_id", response.data.id);
        GetMaker(Endpoints.list_material + course_id).then((res) => {
          if (res) {
            serialize(res.data);
            // console.log("res", res.data);
            // localStorage.setItem("material", JSON.stringify(res.data));
            // localStorage.setItem("material_length", res.data.length);
            // localStorage.setItem("current_material", -1);
            history.push({
              pathname: "/material",
            });
          } else {
            document.getElementById("confirm" + key).innerHTML = "Next";
          }
        });
      }
    });

    // history.push({
    //   pathname: "/training",
    // });
  };

  const serialize = (sections) => {
    var materialsAll = [];
    for (let i = 0; i < sections.length; i++) {
      const materials = sections[i].materials;
      for (let j = 0; j < materials.length; j++) {
        const material = materials[j];
        materialsAll.push(material);
      }
    }
    localStorage.setItem("material", JSON.stringify(materialsAll));
    localStorage.setItem("material_length", materialsAll.length);
    localStorage.setItem("current_material", -1);
  };
  return (
    <div>
      <div className="bg-mine">
        <h1 class="display-4" id="titlex">
          Courses
        </h1>

        <hr class="my-4" />
        {isLoading ? (
          <Spinner animation="grow" />
        ) : (
          courses.map((course, key) => {
            return (
              <Accordion>
                <Card style={{ borderRadius: "20px" }}>
                  <Accordion.Toggle
                    eventKey="0"
                    // className="btn btn-outline-dark"
                    className="course-title"
                    style={{ borderRadius: "20px" }}
                  >
                    {course.name}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div>
                        {course.long_description}
                      </div>
                      <div
                        className="d-flex justify-content-end"
                        style={{ padding: "10px", width: "100%" }}
                      >
                        <button
                          type="button"
                          className="btn-round margin_left"
                          onClick={() => onNext(course.id, key)}
                          id={"confirm" + key}
                        >
                          <i class="fa fa-forward" aria-hidden="true"></i>
                        </button>
                        {/* <Button
                          variant="outline-dark"
                          block
                          onClick={() => onNext(course.id)}
                        >
                          Next
                        </Button> */}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <div style={{ marginTop: "20px" }}></div>
              </Accordion>
            );
          })
        )}

        {/* <Accordion>
          <Card>
            <Accordion.Toggle eventKey="0" className="btn btn-outline-dark">
              Course Name
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div>Course Detail</div>
                <div className="row" style={{ padding: "10px" }}>
                  <Button variant="outline-dark" block onClick={onNext}>
                    Next
                  </Button>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <div style={{ marginTop: "20px" }}></div>
        </Accordion> */}
      </div>
      <a href="/imprint">
          <ImprintFooter>
            <span id="imprint_button">Impressum</span>
          </ImprintFooter>
        </a>
    </div>
  );
};

export default Courses;
