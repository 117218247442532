import React from "react";

const ImprintFooter = ({ children }) => {
  return (
    <div>
      {/* <div style={phantom} /> */}
      <div style={style}>{children}</div>
    </div>
  );
};

var style = {
  backgroundColor: "#F11C0A",
  color: "white",
  textAlign: "right",
  padding: "20px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "50px",
  width: "100%",
  display: "flex",
  flexDirection: "row-reverse",
  alignItems: "center",
};

var phantom = {
  display: "block",
  padding: "20px",
  height: "60px",
  width: "100%",
};

export default ImprintFooter;
