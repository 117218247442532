export const Translations = (language, LanguageJson) => {
  var statements = LanguageJson[language];
  if (language==="") {
    statements = LanguageJson[localStorage.getItem('language')];
  }
  for (var key in statements) {
    //console.log("key", key);
    document.getElementById(key).innerHTML = statements[key];
  }
};
