import React, { useEffect,useState } from "react";
import { Endpoints } from "../../repository/Endpoints";
import { StreamMaker } from "../../repository/RequestMaker";

const TestingUI = () => {
  const [video, setVideo] = useState("");

  useEffect(() => {
    StreamMaker(Endpoints.test_stream_video).then((res) => {
      console.log(res);
      setVideo(res);
    });
  }, []);
  return (
    <div className={"bg-mine"}>
      {video === "" ? "Loading" : <video src={video} height="400" width="300" controls></video>}
    </div>
  );
};

export default TestingUI;
