export default {
    title_text:{
        en:"Congratulations",
        de:"Herzlichen Glückwunsch"
    },
    subtitle_text:{
        en:"Thank you for your participation in the briefing.",
        de:"Vielen Dank für Ihre Teilnahme an der Einweisung.",
    },
    click_hint:{
        en:"E.ON Plattling will keep your certificate for you during the 2023 revision. If you would like to download your certificate, please click on the button below.",
        de:"E.ON Plattling bewahrt Ihr Zertifikat während der Revision 2023 für Sie auf. Falls Sie Ihr Zertifikat herunterladen möchten, klicken Sie bitte auf den Button unten."
    }
}