import React from "react";
import { Link } from "react-router-dom";
const Imprint = () => {
  return (
    <div className="bg-mine">
      <Link to="/">
        <div className="d-flex justify-content-end">
          <button className="btn-smol">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </Link>
      <b>Impressum</b>
      <br />
      <br />
      <b>Angaben gemäß § 5 TMG: </b>
      <br />
      <b>Kraftwerk Plattling GmbH </b>
      <p style={{ margin: 0 }}>Gottlieb-Daimler-Straße 17</p>
      <p style={{ margin: 0 }}>94447 Plattling</p>
      <br />
      <p style={{ margin: 0 }}>
        <b>Kontakt:</b>
      </p>
      <p style={{ margin: 0 }}>Telefon: 09931 895870 </p>
      <p style={{ margin: 0 }}>
        E-Mail:{" "}
        <a href="mailto:info@eon-plattling-unterweisungen.de">
          info@eon-plattling-unterweisungen.de
        </a>
      </p>
      <br />
      <p style={{ margin: 0 }}>
        <b>Vertretung:</b>
      </p>
      <p style={{ margin: 0 }}>
        Geschäftsführung: Andreas Starl und Rainer Bayerke
      </p>
      <br />
      <p style={{ margin: 0 }}>
        <b>Verantwortlich für den Inhalt der Webseite:</b>
      </p>
      <p style={{ margin: 0 }}>Nils Neubecker</p>
      <br />
      <p>
        <b>Registereintrag:</b>
      </p>
      <p>Sitz/Registered Office: München</p>
      <p>Amtsgericht/District Court: München HRB 168887</p>
      <br />
      <p style={{ margin: 0 }}>
        <b>Hinweis zu Streitbeilegungsverfahren:</b>
      </p>

      <p style={{ margin: 0 }}>
        Die Europäische Kommission stellt unter{" "}
        <a href="http://ec.europa.eu/consumers/odr">
          http://ec.europa.eu/consumers/odr
        </a>{" "}
        eine Plattform zur außergerichtlichen Online-Streitbeilegung (sog.
        OS-Plattform) bereit. Wir weisen darauf hin, dass wir weder bereit noch
        verpflichtet sind, an Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle teilzunehmen.
      </p>
      <br />
      <b>Health, Safety & Environment Politik der E.ON Energy Projects GmbH:</b>
      <br />
      <b>
        <a href="https://www.eon.com/content/dam/eon/eon-com/eon-com-assets/documents/eon-energy-projects/de/hse/210215-HSE-Politik-v10-signiert.pdf">
          HSE-Politik
        </a>
      </b>
    </div>
  );
};

export default Imprint;
